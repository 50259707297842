// extracted by mini-css-extract-plugin
export var articleWrapper = "p_m4";
export var searchResult = "p_qh p_m4";
export var line = "p_fg d_fg d_cV";
export var articleTitle = "p_qj d_cm";
export var articleText = "p_m5 d_cp";
export var authorImage = "p_lr d_lr d_b4 d_W d_cq";
export var authorText = "p_nv d_cd d_cq";
export var textCenter = "p_ds d_ds";
export var searchWrapper = "p_qk d_bw d_bL d_dn d_ch d_W";
export var searchInput = "p_ql d_cB d_s d_b1";
export var searchBtn = "p_qm d_bY d_by d_bL d_X d_cz d_dx";
export var text = "p_nF d_cn";
export var elementWrapper = "p_mW d_ch";
export var select = "p_qn d_s d_b1";
export var articleImageWrapper = "p_nl d_cp d_W d_R";
export var articleImageWrapperIcon = "p_nm d_cp d_W d_R";
export var articleImageWrapperColumns = "p_qp d_cp d_fd d_W";
export var contentPadding = "p_qq d_cP";
export var otherPadding = "p_qr d_cJ";
export var paginationWrapper = "p_qs";
export var pagination = "p_qt";
export var disabledPagination = "p_qv";
export var breakMe = "p_qw";
export var activePage = "p_qx";
export var next = "p_qy";
export var pages = "p_qz";
export var searchTitle = "p_qB d_cn";
export var categoryTitle = "p_qC d_cn";
export var searchText = "p_qD d_cq";
export var bold = "p_qF";
export var field = "p_qG";
export var dateTag = "p_nc d_h d_cn d_dm";
export var icon1 = "p_nd d_cL";
export var icon2 = "p_nf d_cL d_cF";
export var tagBtn = "p_ng d_bY d_cz d_dl d_dx";
export var linksWrapper = "p_ny d_cd d_ds";
export var resultImage = "p_qH";