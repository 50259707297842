// extracted by mini-css-extract-plugin
export var alignLeft = "F_pZ d_fl d_bC d_dr";
export var alignCenter = "F_bL d_fm d_bz d_ds";
export var alignRight = "F_p0 d_fn d_bD d_dt";
export var testimonialsContainer = "F_vX d_dS";
export var testimonialsContainerFull = "F_vY d_dQ";
export var testimonialsMainHeader = "F_jt d_jt d_s d_cs";
export var testimonialsComponentParagraph = "F_jw d_jw";
export var testimonialsComponentSmall = "F_jv d_jv";
export var testimonialsComponentsWrapper = "F_jq d_jq d_v d_s d_cr d_b0 d_b2";
export var testimonialsComponentsWrapperDesign2 = "F_jr d_jr d_v d_s d_cr d_b0 d_b2 d_by";
export var testimonialsComponentsWrapperDesign3 = "F_js d_js d_v d_s d_D d_b2 d_bQ d_bw d_bF d_bL d_bz";
export var testimonialsBackgroundColor = "F_vZ d_d4";
export var colEqualHeight = "F_v0 d_bw";
export var testimonialsColumnWrapper = "F_jx d_jx d_b0";
export var testimonialsImageWrapper = "F_jm d_jm d_s d_W";
export var testimonialsImageWrapperDesign2 = "F_jn d_jn d_n d_W d_bt";
export var testimonialsImageWrapperDesign3 = "F_jp d_jp d_W d_bw";
export var design2TextWrapper = "F_sW d_by d_bL d_bF";
export var design3 = "F_v1 d_cz d_D";
export var imageDesign2 = "F_v2";
export var SmallSmall = "F_rq C_rq C_tK C_t5";
export var SmallNormal = "F_rr C_rr C_tK C_t6";
export var SmallLarge = "F_rs C_rs C_tK C_t3";
export var exceptionWeight = "F_r8 C_t9";