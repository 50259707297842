const getCategoryIds = (item) => {
  let result = [item._id];

  if (item.children) {
    result = result.concat(item.children.reduce((acc, child) => acc.concat(getCategoryIds(child)), []))
  }

  return result;
};

export default getCategoryIds;

