// extracted by mini-css-extract-plugin
export var alignDiscLeft = "t_rG d_fl d_bC d_dr";
export var alignLeft = "t_pZ d_fl d_bC d_dr";
export var alignDiscCenter = "t_rH d_fm d_bz d_ds";
export var alignCenter = "t_bL d_fm d_bz d_ds";
export var alignDiscRight = "t_rJ d_fn d_bD d_dt";
export var alignRight = "t_p0 d_fn d_bD d_dt";
export var footerContainer = "t_rK d_dS d_bS";
export var footerContainerFull = "t_rL d_dQ d_bS";
export var footerHeader = "t_kc d_kc";
export var footerTextWrapper = "t_rM d_s";
export var footerDisclaimerWrapper = "t_kk d_kk d_cg";
export var badgeWrapper = "t_rN d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "t_kl d_kl d_bw";
export var footerDisclaimerLeft = "t_km d_km d_bw";
export var verticalTop = "t_rP d_bw d_bF d_bK d_bH";
export var firstWide = "t_rQ";
export var disclaimer = "t_rR";
export var socialDisclaimer = "t_rS";
export var left = "t_rT";
export var wide = "t_rV d_cv";
export var right = "t_rW d_bG";
export var line = "t_fg d_fh d_cr";
export var badgeDisclaimer = "t_rX d_by d_bL d_bF";
export var badgeContainer = "t_rY d_bw d_bD d_bL";
export var badge = "t_rZ";
export var padding = "t_r0 d_c4";
export var end = "t_r1 d_bD";
export var linkWrapper = "t_r2 d_dx";
export var link = "t_m0 d_dx";
export var colWrapper = "t_r3 d_ct";
export var media = "t_r4 d_bt d_dv";
export var itemRight = "t_r5";
export var itemLeft = "t_r6";
export var itemCenter = "t_r7";
export var exceptionWeight = "t_r8 C_t9";