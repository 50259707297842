// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "D_vP d_bz";
export var storyRowWrapper = "D_hv d_hv d_bG";
export var storyLeftWrapper = "D_vQ d_bw d_bL";
export var storyWrapperFull = "D_vR d_cz";
export var storyWrapperFullLeft = "D_pt d_cz d_bw d_bL d_bz";
export var contentWrapper = "D_mY d_hw";
export var storyLeftWrapperCenter = "D_vS d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "D_vT d_hC";
export var storyHeader = "D_vV d_hB d_s d_cp";
export var storyHeaderCenter = "D_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "D_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "D_vW d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "D_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "D_p5 d_fc d_W";
export var imageWrapperFull = "D_rj d_s d_D d_bb d_W";