import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import { formColor } from '../../../../../helper';
import Carousel from '../../../../Carousel';
import CustomSectionMedia from '../../LayoutComponents/CustomSectionMedia';

import * as styles from './styles.module.css';

class TestimonialsLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      columns,
    };

    this.createContent = this.createContent.bind(this);
  }

  createContent(item, index) {
    const smallStyle = `Small${this.props.themeData.typography.default.fontSize}`;
    let style;
    if (item.styles.backgroundColor.active) {
      let { backgroundColor } = item.styles;
      if (item.styles.backgroundColor.solid === '' && item.styles.backgroundColor.gradient.from === '')
        backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
        undefined,
        this.props.themeData.colors,
      );
    }

    const align = `align${item.align}`;
    let cols;
    if (this.props.layout === 'design3') cols = 'col-12 col-md-8 offset-md-2';
    else if (this.state.columns === 1) cols = 'col-12';
    else
      cols = `col-12 col-sm-6 ${
        this.props.layout === 'design2'
          ? this.state.columns
            ? `col-md-${12 / this.state.columns}`
            : ''
          : `col-md-${this.state.columns ? 12 / this.state.columns : 4}`
      }`;

    const design = this.props.layout === 'design2' ? 'Design2' : this.props.layout === 'design3' ? 'Design3' : '';

    let texts = [];
    let image = null;
    item.data.forEach((elem, i) => {
      if (elem.active && elem.type.startsWith('PARAGRAPH/')) {
        let styleName;
        let content;
        if (elem.type === 'PARAGRAPH/PARAGRAPH') {
          styleName = `${styles.testimonialsComponentParagraph} ${styles[align]}`;
          content = HTMLParser(`<span>${elem.text}</span>`);
        } else {
          styleName = `${styles.testimonialsComponentSmall} ${styles[align]}`;
          content = <span className={`${styles[smallStyle]} ${styles.exceptionWeight}`}>{HTMLParser(elem.text)}</span>;
        }

        const text = (
          <div key={`${this.props.section._id}_elem_${index}${i}`} className={styleName}>
            {content}
          </div>
        );
        texts.push(text);
      } else if (elem.active && elem.type === 'IMAGES/IMAGE') {
        const test = !elem.content.icon;
        const data =
          test && this.props.layout === 'design3' ? { round: true, width: 33, ...elem.content } : elem.content;
        const img = (
          <CustomSectionMedia
            data={data}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            categoryPathList={this.props.categoryPathList}
            colors={this.props.themeData.colors}
          />
        );

        let maxWidth;
        if (test && this.props.layout === 'left' && (elem.content.round || elem.content.crop)) {
          maxWidth = { maxWidth: '200px' };
        }

        image = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={clsx(
              styles[`testimonialsImageWrapper${design}`],
              design === 'Design3' && styles[`align${item.align || 'Center'}`],
              this.props.layout === 'design2' && !elem.content.crop && !elem.content.round && styles.imageDesign2,
            )}
            style={maxWidth}
          >
            {img}
          </div>
        );

        if (maxWidth && elem.content.align) {
          image = (
            <div className={styles[`align${elem.content.align}`]} style={{ width: '100%', display: 'flex' }}>
              {image}
            </div>
          );
        }
      }
    });

    if (this.props.layout === 'design2') {
      texts = (
        <div className={styles.design2TextWrapper} style={{ width: item.data[0].active ? '50%' : '100%' }}>
          {texts}
        </div>
      );
    }

    let content = (
      <>
        {image}
        {texts}
      </>
    );

    if (this.props.layout === 'design3') content = <div>{content}</div>;

    return (
      <div
        key={`${this.props.section._id}_layout_${index}`}
        className={`${cols} ${this.props.layout !== 'design3' ? styles.colEqualHeight : styles.design3}`}
      >
        <div className={styles[`testimonialsComponentsWrapper${design}`]} style={style}>
          {content}
        </div>
      </div>
    );
  }

  render() {
    let activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {
      if (item.active && item.type === 'COMPONENT') {
        const content = this.createContent(item, index);
        activeComponents.push(content);
      } else if (item.active && item.type === 'HEADINGS/HEADING-TWO') {
        const text = (
          <div className={`col-12 ${styles.testimonialsMainHeader}`}>{HTMLParser(`<h2>${item.text}</h2>`)}</div>
        );
        top.push(text);
      }

      return null;
    });

    if (this.props.layout === 'design3') {
      activeComponents = (
        <div className="col-12 testimonialsCarousel">
          <Carousel showArrows showIndicators autoPlay={false} infiniteLoop={false}>
            {activeComponents}
          </Carousel>
        </div>
      );
    }

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`testimonialsContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          {top}
          {activeComponents}
        </div>
      </div>
    );
  }
}

export default TestimonialsLayout;
