// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_r9 d_gs d_cp";
export var heroHeaderCenter = "v_gt d_gt d_cp d_ds";
export var heroHeaderRight = "v_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "v_sb d_gp d_cs";
export var heroParagraphCenter = "v_gq d_gq d_cs d_ds";
export var heroParagraphRight = "v_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "v_sc d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "v_sd d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "v_sf d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "v_sg d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "v_sh d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "v_sj C_sj";
export var heroExceptionNormal = "v_sk C_sk";
export var heroExceptionLarge = "v_sl C_sl";
export var Title1Small = "v_sm C_sm C_tK C_tL";
export var Title1Normal = "v_sn C_sn C_tK C_tM";
export var Title1Large = "v_sp C_sp C_tK C_tN";
export var BodySmall = "v_sq C_sq C_tK C_t2";
export var BodyNormal = "v_sr C_sr C_tK C_t3";
export var BodyLarge = "v_ss C_ss C_tK C_t4";