// extracted by mini-css-extract-plugin
export var alignLeft = "x_pZ d_fl d_bC d_dr";
export var alignCenter = "x_bL d_fm d_bz d_ds";
export var alignRight = "x_p0 d_fn d_bD d_dt";
export var productsContainer = "x_sB d_dS";
export var productsTextWrapper = "x_sC d_X d_6 d_s d_cC";
export var productsImageElement = "x_lJ";
export var productsModalWrapper = "x_sD d_bw d_bH";
export var productsModalWrapperDesign3 = "x_l4 d_l4 d_bw d_bJ d_bQ d_bz d_cC d_bb";
export var modalLeftColumn = "x_sF d_n d_D";
export var modalLeftColumnDesign2 = "x_sG d_n d_D";
export var modalCloseIcon = "x_sH d_dx";
export var modalRightColumn = "x_sJ d_n d_D d_bQ d_bw d_bJ d_cC";
export var productsComponentsWrapperLeft = "x_l1 d_l1 d_v d_s d_cr d_dx";
export var modalCloseButton = "x_sK d_s d_bw d_bH d_bL d_bD";
export var modalCloseButtonDesign3 = "x_sL d_s d_bw d_bH d_bL d_bD";
export var modalTextWrapperDesign3 = "x_sM d_D d_bw d_bJ d_bL";
export var modalTextWrapper = "x_sN d_bw d_bJ d_bL";
export var modalCarouselWrapper = "x_sP d_D d_bw d_bH d_bz";
export var carouselWrapper = "x_k9 d_D d_bw d_bH d_bz";
export var productsCarouselImageWrapper = "x_sQ d_lW d_D d_s";
export var productsCarouselImageWrapperSides = "x_sR d_D";
export var productsCarouselImageWrapperDesign3 = "x_lX d_lX d_bb";
export var productsCarouselWrapper = "x_lY d_lY d_bw d_bz d_bL d_s d_D";
export var productsCarouselWrapperDesign3 = "x_lZ d_lZ d_bw d_bz d_bL d_s";
export var productsCarouselImg = "x_mH d_l0 d_s d_D d_bN";
export var productsImageModalWrapper = "x_lL";
export var productsImageElementDesign3 = "x_lM d_lM d_hF d_s d_D d_bN";
export var productsContainerFull = "x_sS d_dQ";
export var productsMainHeader = "x_l6 d_l6 d_l5 d_s d_ds d_cq";
export var productsMainSubtitle = "x_l7 d_l7 d_l5 d_s d_ds d_cv";
export var productsComponentHeading = "x_l8 d_l8";
export var productsComponentParagraph = "x_l9 d_l9";
export var productsCard = "x_lN d_lN d_9 d_X d_5 d_4 d_1 d_6 d_bv d_v d_S d_D";
export var productsCardWrapper = "x_lP d_lP d_bv d_s d_W";
export var productsCardWrapperDesign2 = "x_lQ d_lQ d_s d_D d_bw d_bJ d_bQ";
export var productsTextBlockDesign2Wrapper = "x_sT d_cC";
export var productsTextBlockDesign3Wrapper = "x_lR d_lR d_s d_bw d_bJ d_bz d_bL d_cC d_fk d_X";
export var productsTextBlockDesign2 = "x_lS d_lS d_bw d_bJ d_D";
export var productsTextBlockDesign3 = "x_lT d_lT d_bw d_bJ d_bL d_bh";
export var productsComponentsWrapperDesign2 = "x_l2 d_l2 d_v d_s d_bw d_bJ d_cr d_bQ";
export var productsComponentsWrapperDesign3 = "x_l3 d_l3 d_v d_s d_bw d_bJ d_cr d_dx";
export var colEqualHeights = "x_sV d_bw";
export var productsImageWrapperLeft = "x_lF d_lF d_s d_W d_bt";
export var productsImageWrapperDesign2 = "x_lG d_lG d_s";
export var design2TextWrapper = "x_sW d_n d_by d_bL d_bF";
export var exceptionWeight = "x_r8 C_t9";