// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "M_fM d_fM d_bw d_bD";
export var navbarDividedRight = "M_fN d_fN d_bw";
export var menuLeft = "M_xh d_fK d_bw d_ds d_bL";
export var menuRight = "M_xj d_fK d_bw d_ds d_bL";
export var menuCenter = "M_xk d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var menuDivided = "M_v7 d_fL d_fK d_bw d_ds d_bL d_s d_bz";
export var navbarItem = "M_v8 d_bt";
export var navbarLogoItemWrapper = "M_fV d_fV d_by d_bL";
export var burgerToggle = "M_xl d_f8 d_bv d_bp d_W";
export var burgerToggleOpen = "M_xm d_f8 d_bv d_bp d_0";
export var burgerInput = "M_xn d_fZ d_t d_bv d_X d_b8 d_dh d_bq";
export var burgerLine = "M_xp d_fY d_bv d_W d_t d_bj d_cl";
export var burgerMenuLeft = "M_xq d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuRight = "M_xr d_f4 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuCenter = "M_xs d_f5 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var burgerMenuDivided = "M_xt d_f3 d_f2 d_f0 d_f1 d_X d_b8 d_dj d_bp d_ds";
export var btnWrapper = "M_dZ d_cT d_cM d_bw d_bD d_s";
export var cancelBtn = "M_xv d_bY d_cz d_dx";
export var icon = "M_nD";
export var secondary = "M_xw d_by d_bL";