// extracted by mini-css-extract-plugin
export var articleWrapper = "j_m4";
export var articleText = "j_m5 d_dr d_cp";
export var header = "j_m6 d_W d_s";
export var headerImageWrapper = "j_m7 d_9 d_X d_5 d_4 d_1 d_6 d_bg d_bN";
export var headerGradient = "j_m8 d_9 d_X d_5 d_4 d_1 d_6";
export var headerGradientOverlay = "j_m9 d_9 d_X d_5 d_4 d_1 d_6";
export var headerContentWrapper = "j_nb d_bw d_bL d_bz d_bF d_bh d_W";
export var contentWrapper = "j_mY d_s";
export var dateTag = "j_nc d_cb d_cn d_dm d_s";
export var icon1 = "j_nd d_cL";
export var icon2 = "j_nf d_cL d_cF";
export var tagBtn = "j_ng d_bY d_cz d_dl d_dx";
export var headerText = "j_nh d_s d_cb d_cp d_bS";
export var center = "j_nj d_ds";
export var videoIframeStyle = "j_nk d_d2 d_s d_D d_bv d_bY d_N";
export var articleImageWrapper = "j_nl d_cp d_W";
export var articleImageWrapperIcon = "j_nm d_cp d_W";
export var articleRow = "j_nn d_bz d_bb";
export var quoteWrapper = "j_gN d_by d_bL d_cp";
export var quoteBar = "j_np d_D";
export var quoteText = "j_nq";
export var authorBox = "j_nr d_s";
export var authorRow = "j_ns d_bz d_bS d_cv";
export var separator = "j_nt d_s";
export var line = "j_fg d_s d_fg d_cV d_cv";
export var authorImage = "j_lr d_b4 d_W d_s d_cq";
export var authorText = "j_nv d_cd d_cq";
export var masonryImageWrapper = "j_nw";
export var bottomSeparator = "j_nx d_s d_cv";
export var linksWrapper = "j_ny d_cd d_dr";
export var comments = "j_nz d_bz d_cv";
export var sharing = "j_nB d_bw d_bz d_bF";
export var shareText = "j_nC d_s d_ds";
export var icon = "j_nD";
export var text = "j_nF";
export var SubtitleSmall = "j_nG";
export var SubtitleNormal = "j_nH";
export var SubtitleLarge = "j_nJ";