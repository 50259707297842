// extracted by mini-css-extract-plugin
export var carouselContainer = "L_wt d_s d_D d_bb d_W";
export var carouselContainerCards = "L_wv L_wt d_s d_D d_bb d_W";
export var carouselContainerSides = "L_ww d_s d_D d_W";
export var prevCarouselItem = "L_wx d_s d_D d_X d_g";
export var prevCarouselItemL = "L_wy L_wx d_s d_D d_X d_g";
export var moveInFromLeft = "L_wz";
export var prevCarouselItemR = "L_wB L_wx d_s d_D d_X d_g";
export var moveInFromRight = "L_wC";
export var selectedCarouselItem = "L_wD d_s d_D d_W d_bb";
export var selectedCarouselItemL = "L_wF L_wD d_s d_D d_W d_bb";
export var selectedCarouselItemR = "L_wG L_wD d_s d_D d_W d_bb";
export var nextCarouselItem = "L_wH d_s d_D d_X d_g";
export var nextCarouselItemL = "L_wJ L_wH d_s d_D d_X d_g";
export var nextCarouselItemR = "L_wK L_wH d_s d_D d_X d_g";
export var arrowContainer = "L_wL d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainer = "L_wM L_wL d_D d_X d_dx d_bh d_1 d_bY";
export var prevArrowContainerHidden = "L_wN L_wM L_wL d_D d_X d_dx d_bh d_1 d_bY d_g";
export var nextArrowContainer = "L_wP L_wL d_D d_X d_dx d_bh d_1 d_bY";
export var carouselContainerProducts = "L_wQ";
export var nextArrowContainerHidden = "L_wR L_wP L_wL d_D d_X d_dx d_bh d_1 d_bY d_g";
export var arrow = "L_kK d_X";
export var prevArrow = "L_wS L_kK d_X";
export var nextArrow = "L_wT L_kK d_X";
export var carouselIndicatorContainer = "L_wV d_s d_ds d_bh d_bw d_bz d_bL d_bF d_X";
export var btnsContainer = "L_wW d_s d_bw d_bB";
export var carouselText = "L_wX d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var carouselTextInactive = "L_wY L_wX d_dm d_bw d_bz d_bL d_bY d_dx d_cz";
export var indicator = "L_wZ d_b4";
export var carouselIndicator = "L_w0 L_wZ d_b4";
export var carouselIndicatorSelected = "L_w1 L_wZ d_b4";
export var arrowsContainerTopRight = "L_w2 d_X d_bh d_by";
export var arrowsContainerBottomLeft = "L_w3 d_X d_bh d_by";
export var arrowsContainerSides = "L_w4 d_X d_bh d_by";
export var smallArrowsBase = "L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainer = "L_w6 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrev = "L_w7 L_w6 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerSmall = "L_w8 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var smallArrowContainerPrevSmall = "L_w9 L_w8 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz";
export var icon = "L_nD";
export var iconSmall = "L_xb";
export var multipleWrapper = "L_xc d_by d_bB d_bb";
export var multipleImage = "L_xd d_by";
export var sidesPrevContainer = "L_xf L_w6 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";
export var sidesNextContainer = "L_xg L_w6 L_w5 d_bQ d_bw d_bz d_bL d_bY d_dx d_cz d_fk d_X d_bh d_cz";